type PositiveNegativeAnswers = string[];

export const positiveAnswers: PositiveNegativeAnswers = [
  "yes",
  "ja",
  "si",
  "sim",
  "da",
  "sì",
  "ναι",
  "igen",
  "yes, by myself or with people I live with",
  "ja, alleen of alleen met mensen die bij je in huis wonen",
  "si, solo o con las personas que vivo",
  "sim, sozinho ou com pessoas com quem vivo",
  "ja, alleine oder mit Leuten mit denen ich zusammenwohne",
  "ja, allan oder mit Leuten mit denen ich zamwohn",
  "da, singur/ă sau cu cei cu care locuiesc",
  "ja, alene eller de jeg bor med",
  "ja, allene eller med huskammerater",
  "sì, esco da solo o con persone con cui vivo",
  "ναι, μόνος/η μου ή με άτομο που μένω μαζί του",
  "igen, egyedül, vagy a velem egy háztartásban élőkkel megyek",
];

export const negativeAnswers: string[] = [
  "no",
  "nee",
  "não",
  "nein",
  "na",
  "nu",
  "nei",
  "nej",
  "όχι",
  "nem",
  "no, with others",
  "nee, ook met andere mensen",
  "no, con mas gente",
  "não, vou sair com outras pessoas",
  "nein, mit anderen Leuten",
  "na, mit anderen Leuten",
  "nu, cu altcineva",
  "nei, med andre",
  "nej, med andre mennesker",
  "no, esco con altri",
  "όχι, με κάποιον άλλο",
  "nem, másokkal megyek",
];
